.page-footer {
    background-color: #222;
    position: absolute;
    bottom: 0;
    width: 100%;
}
.fa {
    padding: 20px;
    font-size: 30px;
    width: 50px;
    text-align: center;
    text-decoration: none;
    margin: 5px 2px;
}
.fa:hover {
    opacity: 0.7;
}
.fa-linkedin {
    background: #007bb5;
    color: white;
    padding: 10px;
}
.fa-github {
    background: #000;
    color: white;
    padding: 10px;
}