.About-Page {
    background-color: #fff;
    font-size: calc(10px + 2vmin);
    color: white;
    padding: 20px 50px;
    border-bottom-left-radius: 150px;
    color: #222;
    text-align: center;
    height: 100%;
}
.profile-foto {
    height: 40vmin;
    pointer-events: none;
    border-radius: 10px;
    animation: fadeIn ease 5s;
    -webkit-animation: fadeIn ease 5s;
    -moz-animation: fadeIn ease 5s;
    -o-animation: fadeIn ease 5s;
    -ms-animation: fadeIn ease 5s;
}
@keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

@-moz-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

@-o-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

@-ms-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}
.main-title {
    margin-top: 100px;
}
.About-Page p {
    
    font-size: 16px;
}