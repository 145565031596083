nav {
    background-color: #222;
}
.nav-wrapper {
    margin: 0 20px;
}
.nav-wrapper a:hover {
    color: #818181;
}
.switch-dark-btn {
    background: transparent;
    border: none;
    color: #e6e6e6;
}
.switch-dark-btn:active {
    background: transparent;
    border: none;
    color: #e6e6e6;
}
.switch-dark-btn:hover {
    color: #FFFF00;
}
.side {
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #111;
    overflow-x: hidden;
    padding-top: 60px;
    margin: 0;
    transition: 0.5s;
}
.side a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #e6e6e6;
    display: block;
    transition: 0.3s;
}
.side a:hover {
    color: #818181;
}
.side .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
}
@media screen and (max-height: 450px) {
    .side {padding-top: 15px;}
    .side a {font-size: 18px;}
}