.Home-Page {
    text-align: center;
}
.col {
    margin-top: 20px;
    overflow: hidden;
}
.intro {
    background-color: black;
    text-align: center;
    min-width: 100vw;
    height: 50vh;
    color: white;
    margin: 0;
    background-image: url('../../images/business-code-coding-computer-270360.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 50px;
}
.intro span {
    background-color: rgba(0, 0, 0, 0.8);
}
.main-title {
    margin: 0;
    padding-top: 40px;
    position: relative;
    z-index: 1;
}
.lang-title{
    font-weight: bold;
    padding: 20px;
    margin: 0;
}
.second {
    background-image: url('../../images/pc-office.jpg');
    text-align: center;
    min-width: 100vw;
    height: 50vh;
    color: white;
    margin: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 40px;
}
.mainpage-jumb {
    min-width: 60px;
    min-height: 100px;
    z-index: 0;
}
.main-header-5 {
    position: relative;
    z-index: 1;
}
@media screen and (max-width: 600px) {
    .col h3 {
        font-size: 6vw;
    }
}
@media screen and (min-width: 600px) {
    .intro {
        background-attachment: fixed;
    }
    .second {
        background-attachment: fixed;
    }
}