.Portfolio-Page { 
    margin-top: 50px;
    text-align: center;
}
.card {
    color: #222;
    background-color: #fff;
    height: 50vh;
}
.card .card-action a:not(.btn):not(.btn-large):not(.btn-small):not(.btn-large):not(.btn-floating) {
    color: #4259b3;
}
.card .card-action a:not(.btn):not(.btn-large):not(.btn-small):not(.btn-large):not(.btn-floating):hover {
    color: #111;
}
.card-image img {
    height: 30vh;
}
iframe {
    width: 100%;
    height: 40vh;
    padding: 0;
    margin: 0;
}
.phone-app {
    max-width: 30vw;
}